import React from "react";

function CommonPageHead({ heading, brief, image, reverse }) {
  return (
    <>
      <div
        className="landing-basic-details common-page-head-maxin"
        style={reverse && { flexDirection: "row-reverse" }}
      >
        <div className="landing-basic-details-image common-page-head-image">
          <img src={image} alt="basic-img" />
        </div>
        <div className="landing-basic-details-content common-page-head-content">
          <h1 style={reverse && { textAlign: "end" }}> {heading} </h1>
          <p style={reverse && { textAlign: "end" }}>{brief}</p>
        </div>
      </div>
    </>
  );
}

export default CommonPageHead;
