import React from "react";
import Loading from "../../Assets/Images/blockspng.png";
import "./loader.css";

export default function Loader({ size, style }) {
  return (
    <>
      <div className="loader-container" style={style}>
        {/* <p style={{ position: "absolute", top: "56%" }}>Thnad rakh</p> */}
        <div className="loader-main">
          <img src={Loading} alt="loader" />
        </div>
      </div>
    </>
  );
}
