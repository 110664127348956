import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "./activation.css";
import TopNav from "../../../Components/TopNav/TopNav";

function Activation() {
  const [message, setMessage] = useState("Please Wait...");
  const { token } = useParams();
  useEffect(() => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/user/activate/${token}`)
      .then((response) => response.json())
      .then((data) => {
        setMessage(data.message);
      });
  }, [token]);

  return (
    <>
      <TopNav />
      <section className="activation-main">
        <div className="activation-reset">{message}</div>
        <Link to="/Authentication/login">Login</Link>
      </section>
    </>
  );
}

export default Activation;
