import React, { useEffect, useState } from "react";
import TopNav from "../../../Components/TopNav/TopNav";
import "./landing.css";
import {
  HiOutlineDocumentDuplicate,
  HiOutlineDocumentCheck,
  HiOutlineTruck,
} from "react-icons/hi";
import LandingFocus from "../../../Components/LandingFocus/LandingFocus";
import LandingBasicDetails from "../../../Components/LandingFocus/LandingBasicDetails";
import WorkingStep from "../../../Components/LandingFocus/WorkingStep";
import { FiUpload } from "react-icons/fi";
import { BsFileEarmarkCheck } from "react-icons/bs";
import Aadhar from "../../../Assets/Images/aadhar.png";
import { AiOutlineLine } from "react-icons/ai";
import ServiceCard from "../../../Components/LandingFocus/ServiceCard";
import { Link } from "react-router-dom";
import Whatfor from "../../../Assets/Images/whatfor.png";
import Process from "../../../Assets/Images/process.png";
import Deliver from "../../../Assets/Images/deliver.png";
import Updated from "../../../Assets/Images/updated.png";
import Loader from "../../../Components/Loader/Loader";

function Landing() {
  const [services, setServices] = useState([]);
  const [loader, setLoader] = useState(false);

  const workingSteps = [
    {
      icon: <HiOutlineDocumentDuplicate />,
      content: "Select document or apply for updation",
    },
    {
      icon: <FiUpload />,
      content: "Provide required details and documents",
    },
    {
      icon: <BsFileEarmarkCheck />,
      content: "Submit form and track your delivery",
    },
    {
      icon: <HiOutlineTruck />,
      content: "Get your desired document delivered",
    },
  ];

  useEffect(() => {
    setLoader(true);
    fetch(`${process.env.REACT_APP_BASE_URL}/user/all_services`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      response.json().then((data) => {
        if (response.ok) {
          data.length = 6;
          setServices(data);
          setLoader(false);
        } else {
          alert("Something went wrong");
          setLoader(false);
        }
      });
    });
  }, []);

  return (
    <>
      <section className="landing-home">
        <TopNav />
        <LandingFocus />
      </section>
      <section className="landing-working-brief ">
        <div className="landing-working-inner individual-section">
          <LandingBasicDetails
            heading={"What are we here for ? "}
            brief={
              <ul>
              <li>Welcome to Indian government document consultancy website</li>
              <li>Provides a convenient and secure way to access important government documents</li>
              <li>Documents available include birth certificates, marriage licenses, and passport applications</li>
              <li>User-friendly platform offers online document creation and submission</li>
              <li>Saves you time and hassle</li>
              <li>Provides accurate information</li>
              <li>Ensures secure personal data protection</li>
            </ul>
            
              
            }
            image={Whatfor}
          />
          <div className="landing-working-steps">
            {workingSteps?.map((item, index) => {
              return (
                <WorkingStep
                  key={item.icon}
                  icon={item.icon}
                  content={item.content}
                />
              );
            })}
          </div>
        </div>
      </section>

      <section className="landing-working-brief landing-service-main">
        <div className="landing-services-inner individual-section">
          <h1>༺ Apply for service ༻</h1>
          <div className="landing-individual-container">
            {loader ? (
              <Loader />
            ) : (
              services?.map((service, index) => {
                return (
                  <ServiceCard
                    key={service.id}
                    name={service.details.name}
                    serviceImg={`${process.env.REACT_APP_BASE_URL}${service.image}`}
                    hash={service.id}
                  />
                );
              })
            )}
          </div>
          <Link
            to={`/services`}
            className="landing-service-explore-more primary-btn"
          >
            Load more
          </Link>
        </div>
      </section>
      <section className="landing-working-brief">
        <div className="landing-services-inner landing-instruction-main individual-section ">
          <h1>༺ Instructions for beneficiary ༻</h1>
          <div className="landing-working-inner individual-section">
            <LandingBasicDetails
              heading={"How we process your requested documents?"}
              brief={
                <ul>
                <li>Secure and efficient document processing</li>
                <li>Team of experts verifies information</li>
                <li>Processes request with accuracy</li>
                <li>Delivers documents via mail or in-person pickup</li>
                <li>Prioritizes privacy and security</li>
                <li>Professional handling of requested documents</li>
              </ul>
              
              }
              image={Process}
              reverse={true}
            />
            <LandingBasicDetails
              heading={"How we deliver your important documents safely?"}
              brief={
                <ul>
                <li>At A2Zdocs.com, we prioritize the safe delivery of your important documents.</li>
                <li>Choose between mail or in-person pickup with secure delivery options.</li>
                <li>Trust in our encryption and advanced security measures to protect your personal information at all times.</li>
                <li>Rest easy knowing your documents are in good hands.</li>
              </ul>
              }
              image={Deliver}
            />
            <LandingBasicDetails
              heading={"How we can get your documents updated as well? "}
              brief={
                <ul>
                <li>A2Zdocs.com offers document update services</li>
                <li>Ensuring your documents are up-to-date and compliant with regulations</li>
                <li>Our team will guide you through the process</li>
                <li>Handle updating your information efficiently</li>
                <li>Trust us to keep your documents accurate and in good hands</li>
              </ul>
              
              }
              image={Updated}
              reverse={true}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Landing;
