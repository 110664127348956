import React from "react";
import { useState, useEffect } from "react";
import Loader from "../../../Components/Loader/Loader";

function BasicDetails() {
  const [loader, setLoader] = useState(false);
  const token = localStorage.getItem("token");
  let timeout;

  const [details, setDetails] = useState({});
  const [responseMessage, setResponseMessage] = useState("");
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/user/user_details`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `token ${token}`,
      },
    }).then((response) => {
      response.json().then((data) => {
        if (response.ok) {
          setDetails(data);
        } else {
          alert("Something went wrong");
        }
      });
    });
  }, []);

  const updateDetails = () => {
    setLoader(true);
    clearTimeout(timeout);

    fetch(`${process.env.REACT_APP_BASE_URL}/user/update/user_details`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Request-Headers": true,
        Authorization: `token ${token}`,
      },
      body: JSON.stringify(details),
    }).then((response) => {
      response.json().then((data) => {
        if (response.ok) {
          setLoader(false);
          setResponseMessage(data.message);
          timeout = setTimeout(() => setResponseMessage(""), 2500);
        } else {
          setLoader(false);
          setResponseMessage(data.message);
        }
      });
    });
  };

  return (
    <div className="profile-type-basic">
      <form
        className="service-individual-form profile-basic-form"
        onSubmit={(e) => {
          e.preventDefault();
          updateDetails();
        }}
      >
        <div className="continous-inputs">
          <div className="service-individual-input">
            <label htmlFor="Name">Name</label>
            <input
              type="text"
              value={details?.name}
              onChange={(e) => setDetails({ ...details, name: e.target.value })}
              required
            />
          </div>
          <div className="service-individual-input">
            <label htmlFor="username">Username</label>
            <input
              className="disabled-input"
              type="text"
              disabled
              required
              value={details?.username}
            />
          </div>
        </div>

        <div className="service-individual-input">
          <label htmlFor="Name">Email</label>
          <input
            type="email"
            value={details?.email}
            onChange={(e) => setDetails({ ...details, email: e.target.value })}
            required
          />
        </div>
        <div className="service-individual-input">
          <label htmlFor="Name">Phone</label>
          <input
            type="number"
            value={details?.phone_number}
            onChange={(e) =>
              setDetails({ ...details, phone_number: e.target.value })
            }
            required
          />
        </div>
        {responseMessage?.length > 0 && (
          <p className="response-message">{responseMessage}</p>
        )}
        {loader ? (
          <Loader />
        ) : (
          <button className="secondary-btn">Submit</button>
        )}
      </form>
    </div>
  );
}

export default BasicDetails;
