import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import IndividualSideSection from "../../../Components/IndividualDetail/IndividualSideSection";
import AadharCard from "../../../Assets/Images/aadhar.png";
import "./applyform.css";
import { ACTIONS, formContext } from "../../../App";
import TopNav from "../../../Components/TopNav/TopNav";
import Loader from "../../../Components/Loader/Loader";

function ApplyForm() {
  const formData = useContext(formContext);
  const navigate = useNavigate();
  const formDataMain = new FormData();
  const { id } = useParams();
  useEffect(() => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();

    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }

    today = yyyy + "-" + mm + "-" + dd;
    document.querySelector(".DOB")?.setAttribute("max", today);
  }, []);

  const [inputFieldsData, setInputFieldsData] = useState({});

  const [service, setService] = useState(null);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const formSubmitData = (e) => {
    e.preventDefault();
    setLoader(true);

    Object.keys(inputFieldsData).forEach((key) => {
      formDataMain.append(key, inputFieldsData[key]);
    });
    formDataMain.append("class_type", id);
    formDataMain.append("user", 1);
    // formData.formContentDispatch({
    //   type: ACTIONS.FORM_DATA,
    //   payload: [],
    // });

    const token = localStorage.getItem("token");
    // const token = "";
    fetch(`${process.env.REACT_APP_BASE_URL}/user/submit/form/${id}`, {
      method: "POST",
      headers: {
        Authorization: `token ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
      body: formDataMain,
    }).then((response) => {
      response.json().then((data) => {
        if (response.ok) {
          navigate(`/services/review/${data.id}`);
          setLoader(false);
        } else {
          setLoader(false);
          alert("Something went wrong");
        }
      });
    });
  };

  useEffect(() => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_BASE_URL}/user/all_services/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      response.json().then((data) => {
        if (response.ok) {
          setService(data[0]);
          setLoading(false);
        } else {
          alert("Something went wrong");
          setLoading(false);
        }
      });
    });
  }, []);

  const fieldClass = (key) => {
    // console.log(key);
    if (key === "date") {
      return "DOB";
    } else if (key === "file") {
      return "custom-file-input";
    }
  };

  return (
    <>
      <TopNav />
      <section className="service-individual-container">
        <div className="service-individual-detailed">
          <div className="service-individual-head">
            <h1>Apply for new {service?.details?.name}</h1>
            <h3>Fill form required to issue new document</h3>
            <p className="additional-info">
              Note: All the fields are mandatory to fill
            </p>
          </div>
          <form onSubmit={formSubmitData} className="service-individual-form">
            {loading && <Loader />}
            {service &&
              Object.keys(service?.inputs).map((key) => {
                return (
                  <div key={key} className="service-individual-input">
                    <label htmlFor="Name">{key}</label>
                    <input
                      className={fieldClass(service?.inputs[key])}
                      type={service?.inputs[key]}
                      value={inputFieldsData?.[service?.inputs[key]]}
                      required
                      onChange={(e) => {
                        if (service?.inputs[key] === "file") {
                          setInputFieldsData({
                            ...inputFieldsData,
                            [key.replace(/ /g, "_").toLowerCase()]:
                              e.target.files[0],
                          });
                        } else {
                          setInputFieldsData({
                            ...inputFieldsData,
                            [key.replace(/ /g, "_").toLowerCase()]:
                              e.target.value,
                          });
                        }
                      }}
                    />
                  </div>
                );
              })}

            <div className="service-individual-input">
              <label htmlFor="Name">Gender</label>
              <div className="service-input-radio-group">
                <div className="service-radio-group-label">
                  <label> Male </label>
                  <input
                    onChange={(e) =>
                      setInputFieldsData({
                        ...inputFieldsData,
                        gender: e.target.value,
                      })
                    }
                    type="radio"
                    name="gender"
                    value={"Male"}
                    required
                  />
                </div>
                <div className="service-radio-group-label">
                  <label> Female </label>
                  <input
                    type="radio"
                    name="gender"
                    value={"Female"}
                    onChange={(e) =>
                      setInputFieldsData({
                        ...inputFieldsData,
                        gender: e.target.value,
                      })
                    }
                    required
                  />
                </div>
                <div className="service-radio-group-label">
                  <label> Rather not say </label>
                  <input
                    type="radio"
                    name="gender"
                    value={"Others"}
                    onChange={(e) =>
                      setInputFieldsData({
                        ...inputFieldsData,
                        gender: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
            </div>

            {/* <div className="service-individual-input">
              <label htmlFor="file">Upload birth certificate</label>
              <input type="file" className="custom-file-input" />
            </div> */}
            <div
              className="instruction-confirmation"
              style={{ marginTop: "30px" }}
            >
              <div className="instruciton-confirmation-text">
                <input type="checkbox" required />
                <p>
                  I acknowledge that I’ve read and understand all the
                  instructions mentioned on this page, which is responsible for
                  the safety of my confidential documents.
                </p>
              </div>
              {loader ? (
                <Loader />
              ) : (
                <button className="secondary-btn">Proceed</button>
              )}
            </div>
          </form>
        </div>
        <IndividualSideSection
          image={process.env.REACT_APP_BASE_URL + service?.image}
          hash={id}
          price={service?.price_gst_added}
          gst={service?.gst_percentage}
        />
      </section>
    </>
  );
}

export default ApplyForm;
