import React, { useState, useEffect } from "react";
import "./profile.css";
import { AiFillEye } from "react-icons/ai";
import TopNav from "../../../Components/TopNav/TopNav";
import BasicDetails from "./BasicDetails";
import Account from "./Account";

function Profile() {
  const [profileType, setProfileType] = useState("basic-details");
  const profileTypes = {
    BASIC: "basic-details",
    ACCOUNT: "account-details",
  };

  return (
    <>
      <TopNav />
      <section className="landing-working-brief ">
        <div
          className="landing-working-inner individual-section"
          style={{ marginTop: "80px", gap: "0", marginBottom: "-70px" }}
        >
          <div className="profile-types-container">
            <div
              className={
                profileType === profileTypes.BASIC
                  ? "current-profile-tab profile-type"
                  : "profile-type"
              }
              onClick={() => setProfileType(profileTypes.BASIC)}
            >
              Basic Details
            </div>
            <div
              className={
                profileType === profileTypes.ACCOUNT
                  ? "current-profile-tab profile-type"
                  : "profile-type"
              }
              onClick={() => setProfileType(profileTypes.ACCOUNT)}
            >
              Accounts
            </div>
          </div>

          {profileType === profileTypes.BASIC && <BasicDetails />}
          {profileType === profileTypes.ACCOUNT && <Account />}
        </div>
      </section>
    </>
  );
}

export default Profile;
