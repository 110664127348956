function downloadPDF(url) {
  const token = localStorage.getItem("token");
  fetch(`${url}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `token ${token}`,
    },
  }).then((response) => {
    response.json().then((data) => {
      if (response.ok) {
        const pdf = data;
      }
    });
  });
}

function forceDownload(blob, filename) {
  var a = document.createElement("a");
  a.download = filename;
  a.href = blob;
  document.body.appendChild(a);
  a.click();
  a.remove();
}

// Current blob size limit is around 500MB for browsers

export { forceDownload };
