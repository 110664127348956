import React, { useEffect, useState } from "react";
import CommonPageHead from "../../../Components/LandingFocus/CommonPageHead";
import Submissions from "../../../Assets/Images/submission.png";
import { BiSearchAlt } from "react-icons/bi";
import { HiOutlineDocumentText } from "react-icons/hi";
import { AiFillDelete } from "react-icons/ai";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Loader from "../../../Components/Loader/Loader";
import TopNav from "../../../Components/TopNav/TopNav";
import { forceDownload } from "../../../Helpers/downloadPDF";

function Submission() {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [submission, setSubmission] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [q, setQ] = useState("");
  const [searchParam] = useState(["first_name", "last_name", "name"]);
  const [filterParam, setFilterParam] = useState(["All"]);
  const [viewLoading, setViewLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_BASE_URL}/user/all_submissions`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `token ${token}`,
      },
    }).then((response) => {
      response.json().then((data) => {
        if (response.ok) {
          setSubmission(data);
          setLoading(false);
          console.log(data);
        } else {
          alert("Something went wrong");
          setLoading(false);
        }
      });
    });
  }, []);

  function downloadResource(url, filename) {
    setViewLoading(true);
    const token = localStorage.getItem("token");
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `token ${token}`,
      },
      mode: "cors",
    })
      .then((response) => response.blob())
      .then((blob) => {
        let blobUrl = window.URL.createObjectURL(blob);
        forceDownload(blobUrl, filename);
        setViewLoading(false);
      })
      .catch((e) => {
        console.error(e);
        alert(e);
        setViewLoading(false);
      });
  }

  const downloadApp = (id) => {
    downloadResource(
      `${process.env.REACT_APP_BASE_URL}/user/form/pdf/${id}`,
      "Recipit"
    );
  };

  const payment = (id) => {
    navigate(`/services/review/${id}`);
  };

  const deleteSubmission = (id) => {
    setLoader(true);
    fetch(`${process.env.REACT_APP_BASE_URL}/user/submission/delete/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `token ${token}`,
      },
    }).then((response) => {
      response.json().then((data) => {
        if (response.ok) {
          setLoader(false);
          const upSubmission = [...submission];

          const filteredSubmission = upSubmission.filter(
            (sub) => sub.id !== id
          );

          setSubmission(filteredSubmission);
        } else {
          setLoader(false);
          alert("Something went wrong");
        }
      });
    });
  };

  const data = Object.values(submission);

  function search(items) {
    return items.filter((item) => {
      if (item?.form?.name == filterParam) {
        return searchParam.some((newItem) => {
          return item.form[newItem].toLowerCase().indexOf(q.toLowerCase()) > -1;
        });
      } else if (filterParam == "All") {
        return searchParam.some((newItem) => {
          if (newItem === "name") {
            return (
              item?.class_type?.details[newItem]
                .toLowerCase()
                .indexOf(q.toLowerCase()) > -1
            );
          }
          return item[newItem]?.toLowerCase().indexOf(q.toLowerCase()) > -1;
        });
      }
    });
  }

  return (
    <>
      <TopNav />
      <section className="landing-working-brief ">
        <div className="landing-working-inner individual-section">
          <CommonPageHead
            heading={"Pending Submissions"}
            brief={
              "You fill the form but forgot to pay, here you can pay for those form submissions"
            }
            image={Submissions}
            // reverse="true"
          />
          {loading && <Loader />}
          {submission.length > 0 && loading === false && (
            <>
              <div className="order-status-search">
                <h1 className="service-all-heading">
                  <span className="search-service">
                    <BiSearchAlt />
                    <input
                      type="text"
                      placeholder="search document..."
                      value={q}
                      onChange={(e) => setQ(e.target.value)}
                    />
                  </span>
                </h1>
              </div>
              <div className="order-status-top-bar">
                <table>
                  <tr>
                    <th></th>
                    <th>Order items</th>
                    <th>Issued on name</th>
                    <th>Date of submission</th>
                    <th>Pending Submission</th>
                    <th>Payment</th>
                  </tr>
                  {submission.length > 0 &&
                    loading === false &&
                    search(data).map((item) => {
                      return (
                        <tr>
                          <td>
                            {loader ? (
                              <Loader style={{ transform: "Scale(0.7)" }} />
                            ) : (
                              <AiFillDelete
                                style={{ color: "#ff0084", cursor: "pointer" }}
                                title="Delete "
                                onClick={() => deleteSubmission(item.id)}
                              />
                            )}
                          </td>
                          <td>{item.class_type.details?.name}</td>
                          <td>{`${item.first_name} ${item.last_name}`}</td>
                          <td>{item.date_of_submission}</td>
                          <td>
                            {viewLoading ? (
                              <Loader />
                            ) : (
                              <button
                                onClick={() => downloadApp(item?.id)}
                                className="primary-btn"
                              >
                                Download
                              </button>
                            )}
                          </td>
                          <td>
                            <button
                              onClick={() => payment(item.id)}
                              className="primary-btn"
                            >
                              Payment
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </table>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
}

export default Submission;
