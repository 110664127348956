import React from "react";

import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./ProtectedRoute";

const PublicRoutes = () => {
  const auth = useAuth();

  return auth ? <Navigate to="/" /> : <Outlet />;
};

export default PublicRoutes;
