import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import { HiLocationMarker, HiMail, HiPhone } from "react-icons/hi";
import { TbArrowBigRightLine } from "react-icons/tb";
import Logo from "../../Assets/logo.png";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-query-bar">
        <a href="/" className="logo">
          <img src={Logo} alt="logo" />
          {/* <h1>LOGO</h1> */}
        </a>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum
          accusantium dignissimos rerum magnam corrupti nulla, ab quo unde eius
          officiis iusto ex repellat magni quisquam numquam, deserunt distinctio
          iste ipsa?
        </p>
        <div className="service-availability">
          {/* <form className="service-availability-input">
            <input placeholder="Submit your query" type="text" required />
            <button className="primary-btn">
              <TbArrowBigRightLine />
            </button>
          </form> */}
        </div>
      </div>
      <div className="footer-details">
        <ul>
          <li>
            <a href="/"> Home</a>
          </li>
          <li>
            <a href="/services">Services</a>{" "}
          </li>
          <li>
            <a href="/about">About us</a>{" "}
          </li>
          <li>
            <a href="" target="_blank">
              Terms & conditions
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <HiLocationMarker />
            <span>187-H/11, 1st Floor, Jeewan Nagar, Kilokri, Maharani Bagh, New Delhi-110014</span>
          </li>
          <li>
            <HiMail />
            <span>contact@a2zdocs.com</span>
          </li>
          <li>
            <HiPhone />
            <span>+91-9289999601, +91-7065750892, +91-8130898500</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
