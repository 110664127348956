import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import "./reviewandpay.css";
import Aadhar from "../../../Assets/Images/aadhar.png";
import Loader from "../../../Components/Loader/Loader";
import TopNav from "../../../Components/TopNav/TopNav";
import { AiFillInfoCircle } from "react-icons/ai";

function ReviewAndPay() {
  const { formID } = useParams();
  const navigate = useNavigate();
  // const token = "6d676af1f50e4c6b310958c8f5b23de56829f575";
  const token = localStorage.getItem("token");
  const [formdata, setFormdata] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/user/formdata/${formID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${token}`,
      },
    }).then((response) => {
      response.json().then((data) => {
        if (response.ok) {
          setFormdata(data);
        } else {
          alert("Something went wrong");
        }
      });
    });
  }, []);

  const handlePaymentSuccess = (response) => {
    try {
      let bodyData = new FormData();

      // we will send the response we've got from razorpay to the backend to validate the payment
      bodyData.append("response", JSON.stringify(response));

      fetch(`${process.env.REACT_APP_BASE_URL}/user/payment/success/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: bodyData,
      }).then((response) => {
        response.json().then((data) => {
          if (response.ok) {
            navigate(`/services/transaction/${formID}/success`);
          } else {
            navigate(`/services/transaction/${formID}/failed`);
          }
        });
      });
    } catch (error) {
      console.log(console.error());
    }
  };

  // this will load a script tag which will open up Razorpay payment card to make //transactions
  const loadScript = () => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    document.body.appendChild(script);
  };

  const showRazorpay = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await loadScript();

    let bodyData = new FormData();

    // we will pass the amount and product name to the backend using form data
    bodyData.append("amount", formdata?.class_type?.price?.toString());
    bodyData.append("name", formdata?.class_type?.details?.name);
    bodyData.append("id", formID);

    const data = await fetch(`${process.env.REACT_APP_BASE_URL}/user/pay/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `token ${token}`,
      },
      body: bodyData,
    }).then((response) => {
      console.log(response);
      response.json().then((data) => {
        if (response.ok) {
          setLoading(false);
          var options = {
            key_id: process.env.REACT_APP_PUBLIC_KEY, // in react your environment variable must start with REACT_APP_
            key_secret: process.env.REACT_APP_SECRET_KEY,
            amount: data.payment.amount,
            currency: "INR",
            name: "a2zdocs",
            description: "Make documentation easy with a2zdocs",
            image: "", // add image url
            order_id: data.payment.id,
            handler: function (response) {
              // we will handle success by calling handlePaymentSuccess method and
              // will pass the response that we've got from razorpay
              handlePaymentSuccess(response);
            },
            prefill: {
              name: "User's name",
              email: "User's email",
              contact: "User's phone",
            },
            notes: {
              address: "Razorpay Corporate Office",
            },
            theme: {
              color: "#3399cc",
            },
          };

          var rzp1 = new window.Razorpay(options);
          rzp1.open();
        }

        // return data;
      });
    });

    // in data we will receive an object from the backend with the information about the payment
    //that has been made by the user
  };

  return (
    <>
      <TopNav />
      <section className="service-individual-container">
        <div className="service-individual-detailed">
          <div className="service-individual-head">
            <h1>Apply for new {formdata?.class_type?.details?.name}</h1>
            <h3>Order Summary</h3>
          </div>

          <div className="review-mid-container">
            <div className="review-img-container">
              <div className="review-img">
                <img
                  src={`${process.env.REACT_APP_BASE_URL}${formdata?.class_type?.image}`}
                  alt="individual-img"
                />
              </div>
              <button className="secondary-btn">Review provided details</button>
            </div>
            <hr />
            <div className="review-details">
              <p>Deliver to : {formdata?.name}</p>
              <p>Address : {formdata?.delivery_address}</p>
              <p>Pin code : {formdata?.pincode}</p>
              <p>Mobile no : {formdata?.phone_number}</p>
            </div>
            <div className="review-pricing service-individual-pricing-section">
              <h4>
                <sup>₹</sup>
                <span>{formdata?.class_type?.price_gst_added?.toString()}</span>
              </h4>
              <span className="popup-container" style={{ marginTop: "10px" }}>
                *gst included
                <span className="popup-container">
                  <AiFillInfoCircle />
                  <div className="popup-service">
                    +{formdata?.class_type?.gst_percentage?.toString()}% gst
                    included
                  </div>
                </span>
              </span>
            </div>
          </div>

          <div
            className="service-individual-instructions"
            style={{ backgroundColor: "transparent", padding: "0" }}
          >
            <p>Payment Instructions</p>
            <ul style={{ paddingLeft: "40px" }}>
              <li>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s
              </li>
              <li>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s
              </li>
              <li>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s
              </li>
              <li>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s
              </li>
            </ul>
          </div>

          <form onSubmit={showRazorpay} className="instruction-confirmation">
            <div className="instruciton-confirmation-text">
              <input type="checkbox" required />
              <p>
                I acknowledge that I’ve read and understand all the instructions
                mentioned on this page, which is responsible for the safety of
                my confidential documents.
              </p>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <button
                className="primary-btn proceed-to-pay-btn"
                style={{ margin: "auto" }}
              >
                Proceed to pay
              </button>
            )}
          </form>
        </div>
      </section>
    </>
  );
}

export default ReviewAndPay;
