import React, { useState } from "react";
import Loader from "../../../Components/Loader/Loader";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

function Account() {
  const [loader, setLoader] = useState(false);
  const [seeOldPassword, setSeeOldPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [seePassword, setSeePassword] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  let timeout;
  const token = localStorage.getItem("token");
  const regexExp = (type) => {
    return /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{4,}).*$/.test(
      type
    );
  };

  const passwordChange = () => {
    setLoader(true);
    clearTimeout(timeout);

    const data = {
      last_password: currentPassword,
      new_password: newPassword,
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/user/changepassword`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Request-Headers": true,
        Authorization: `token ${token}`,
      },
      body: JSON.stringify(data),
    }).then((response) => {
      response.json().then((data) => {
        if (response.ok) {
          setCurrentPassword("");
          setNewPassword("");
          setConfirmNewPassword("");
          setLoader(false);
          setResponseMessage(data.message);
          timeout = setTimeout(() => setResponseMessage(""), 2500);
        } else {
          setLoader(false);
          setResponseMessage(data.message);
        }
      });
    });
  };

  return (
    <div className="profile-type-basic">
      <form
        className="service-individual-form profile-basic-form"
        onSubmit={(e) => {
          e.preventDefault();
          passwordChange(e);
        }}
      >
        <div className="service-individual-input">
          <label htmlFor="Name">Current Password</label>
          <div className="service-individual-input profile-password-icon-container">
            <input
              type={seeOldPassword ? "text" : "Password"}
              required
              minLength="6"
              value={currentPassword}
              pattern="(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{4,}).*$"
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <span>
              {seeOldPassword ? (
                <AiFillEyeInvisible onClick={(e) => setSeeOldPassword(false)} />
              ) : (
                <AiFillEye onClick={(e) => setSeeOldPassword(true)} />
              )}
            </span>
          </div>
        </div>

        <div className="service-individual-input">
          <label htmlFor="Name">New Password</label>
          <input
            type={seePassword ? "text" : "Password"}
            required
            minLength="6"
            pattern="(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{4,}).*$"
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
          />
        </div>

        <div className="service-individual-input">
          <label htmlFor="Name">Confirm Password</label>
          <div className="service-individual-input profile-password-icon-container">
            <input
              type={seePassword ? "text" : "Password"}
              required
              minLength="6"
              pattern="(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{4,}).*$"
              value={confirmNewPassword}
              onChange={(e) => {
                setConfirmNewPassword(e.target.value);
              }}
            />
            <span>
              {seePassword ? (
                <AiFillEyeInvisible onClick={(e) => setSeePassword(false)} />
              ) : (
                <AiFillEye onClick={(e) => setSeePassword(true)} />
              )}
            </span>
          </div>
        </div>
        {newPassword !== confirmNewPassword && (
          <p className="error-text" style={{ textAlign: "center" }}>
            Password Doesn't match
          </p>
        )}

        {(!regexExp(currentPassword) || !regexExp(newPassword)) &&
          newPassword.length > 0 &&
          currentPassword.length > 0 && (
            <p className="error-text" style={{ textAlign: "center" }}>
              Password must be alphanumeric with special characters
            </p>
          )}

        {responseMessage?.length > 0 && (
          <p className="response-message">{responseMessage}</p>
        )}

        {loader ? (
          <Loader />
        ) : (
          <button
            className="secondary-btn"
            disabled={
              newPassword === confirmNewPassword &&
              newPassword.length > 0 &&
              currentPassword.length > 0
                ? false
                : true
            }
          >
            Submit
          </button>
        )}
      </form>
    </div>
  );
}

export default Account;
