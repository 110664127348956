import React from "react";
import { AiOutlineUser } from "react-icons/ai";
import { useAuth } from "../../Auth/ProtectedRoute";
import { Link } from "react-router-dom";
import "./topnav.css";
import Logo from "../../Assets/logo.png";
import { BiUser, BiLogOutCircle } from "react-icons/bi";

function TopNav() {
  const logout = () => {
    const token = localStorage.getItem("token");
    fetch(`${process.env.REACT_APP_BASE_URL}/user/logout`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `token ${token}`,
      },
    });
    localStorage.clear();
  };
  return (
    <div className="top-nav">
      <Link to={"/"} className="logo">
        <img src={Logo} alt="logo" />
        {/* <h1>LOGO</h1> */}
      </Link>
      <ul className="nav-items">
        <li>
          <Link to={"/services"}> Services</Link>
        </li>
        <li>
          <Link to={"/about"}> About us</Link>
        </li>
        <li>
          <Link to={"/orderstatus"}> Order status</Link>
        </li>
        <li>
          <Link to={"/submissions"}>Submissions</Link>
        </li>
      </ul>

      {useAuth() ? (
        <div className="profile-icon">
          <AiOutlineUser /> Profile
          <div className="profile-icon-menu">
            <ul>
              <li>
                {" "}
                <Link to={"/profile"}>
                  {" "}
                  <BiUser /> View Profile
                </Link>
              </li>

              <li>
                <Link to={"/authentication/login"} onClick={() => logout()}>
                  <BiLogOutCircle /> Logout
                </Link>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <Link
          to={"/authentication/login"}
          style={{ color: "white", fontSize: "20px" }}
          className="primary-btn"
        >
          Login/Sign up
        </Link>
      )}
    </div>
  );
}

export default TopNav;
