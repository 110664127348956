import React from "react";
import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Error from "../Error/Error";
import ProtectedRoutes from "../../Auth/ProtectedRoute";
// import Profile from ''
import Profile from "./Profile/Profile";
import { motion } from "framer-motion";
import OrderStatus from "./OrderStatus/OrderStatus";
import Submission from "./Submission/Submission";
import ApplyForm from "./ApplyForm/ApplyForm";
import TransactionStatus from "./TransactionStatus/TransactionStatus";
import ReviewAndPay from "./ReviewAndPay/ReviewAndPay";

function Home() {
  const style = {
    // position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: "var(--homeBackground)",
    display: "flex",
    // overflow: "hidden",
  };

  const remain = {
    flexGrow: 2,
    overflowY: "auto",
    overflowX: "hidden",
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    fetch(`${process.env.REACT_APP_BASE_URL}/user/check/login`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `token ${token}`,
      },
    }).then((response) => {
      if (response.ok) {
        console.log("perfect");
      } else {
        localStorage.clear();
        window.location.href = "/authentication/login";
        // navigate(`/authentication/login`);
      }
    });
  });

  return (
    <div className="home">
      <Routes>
        <Route path="/" element={<ProtectedRoutes />}>
          <Route path="/submissions" element={<Submission />} />
          <Route path="/services/apply/:id" element={<ApplyForm />} />
          <Route path="/services/review/:formID" element={<ReviewAndPay />} />
          <Route
            path="/services/transaction/:id/:status"
            element={<TransactionStatus />}
          />
          <Route path="/profile" element={<Profile />} />
          <Route path="/orderstatus" element={<OrderStatus />} />
        </Route>
        <Route path="*" element={<Error />} />
      </Routes>
    </div>
  );
}

export default Home;
