import React, { useState, useEffect } from "react";
import ServiceCard from "../../../Components/LandingFocus/ServiceCard";
import Aadhar from "../../../Assets/Images/aadhar.png";
import { BiSearchAlt } from "react-icons/bi";
import Loader from "../../../Components/Loader/Loader";
import TopNav from "../../../Components/TopNav/TopNav";
import { FaFilter } from "react-icons/fa";

function Services() {
  const [services, setServices] = useState([]);
  const [loader, setLoader] = useState(false);
  const [q, setQ] = useState("");
  const [searchParam] = useState(["name"]);
  const [filterParam, setFilterParam] = useState(["All"]);
  const [filters, setFilters] = useState([]);
  useEffect(() => {
    setLoader(true);
    fetch(`${process.env.REACT_APP_BASE_URL}/user/all_services`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      response.json().then((data) => {
        if (response.ok) {
          setServices(data);
          setLoader(false);
        } else {
          alert("Something went wrong");
          setLoader(false);
        }
      });
    });
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/user/all_tags`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      response.json().then((data) => {
        if (response.ok) {
          setFilters(data);
          setLoader(false);
        } else {
          alert("Something went wrong");
          setLoader(false);
        }
      });
    });
  }, []);

  const data = Object.values(services);

  function search(items) {
    return items.filter((item) => {
      if (item.details.name == filterParam || item.tags == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item.details[newItem]
              .toString()
              .toLowerCase()
              .indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (filterParam == "All") {
        return searchParam.some((newItem) => {
          return (
            item.details[newItem]
              .toString()
              .toLowerCase()
              .indexOf(q.toLowerCase()) > -1
          );
        });
      }
    });
  }

  return (
    <>
      <TopNav />
      <section className="landing-working-brief landing-service-main">
        <div
          className="landing-services-inner individual-section"
          style={{ minHeight: "200px" }}
        >
          <h1 className="service-all-heading">
            <span style={{ marginLeft: "auto", marginRight: "100px" }}>
              ༺ All Services ༻
            </span>
            <span style={{ position: "relative" }} className="search-service">
              <BiSearchAlt />
              <input
                type="text"
                id="searchbar"
                placeholder="search document..."
                value={q}
                onChange={(e) => setQ(e.target.value)}
              />
            </span>
            <span>
              {" "}
              <select
                className="custom-filter-dropdown"
                onChange={(e) => setFilterParam(e.target.value)}
              >
                <option value="All">Filter ⚜</option>
                {filters?.map((item, index) => {
                  return <option value={item?.tag}> {item?.tag} </option>;
                })}
              </select>
            </span>
          </h1>
          <div className="landing-individual-container">
            {loader ? (
              <Loader />
            ) : (
              search(data).map((service) => {
                return (
                  <ServiceCard
                    key={service.id}
                    name={service.details.name}
                    serviceImg={`${process.env.REACT_APP_BASE_URL}${service.image}`}
                    hash={service.id}
                  />
                );
              })
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Services;
