import React, { useEffect, useState } from "react";
import AadharCard from "../../../Assets/Images/aadhar.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./instructions.css";
import IndividualSideSection from "../../../Components/IndividualDetail/IndividualSideSection";
import Loader from "../../../Components/Loader/Loader";
import TopNav from "../../../Components/TopNav/TopNav";

function Instructions() {
  const { id } = useParams();
  const [service, setService] = useState(null);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoader(true);
    fetch(`${process.env.REACT_APP_BASE_URL}/user/all_services/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      response.json().then((data) => {
        if (response.ok) {
          setService(data[0]);
          setLoader(false);
        } else {
          alert("Something went wrong");
          setLoader(false);
        }
      });
    });
  }, []);

  const readInstruction = (e) => {
    e.preventDefault();
    navigate(`/services/apply/${id}`);
  };

  return (
    <>
      <TopNav />
      <section className="service-individual-container">
        <div className="service-individual-detailed">
          <div className="service-individual-head">
            <h1>Apply for new {service?.details?.name}</h1>
            <h3>Instructions to apply</h3>
          </div>
          {service?.details?.content?.map((item, index) => {
            return (
              <div
                key={item + index}
                className="service-individual-instructions"
              >
                <p>{item.conthead}</p>
                <ul>
                  {item.contdesc?.map((listItem, index) => {
                    return <li key={listItem + index}>{listItem}</li>;
                  })}
                </ul>
              </div>
            );
          })}

          <form onSubmit={readInstruction} className="instruction-confirmation">
            <div className="instruciton-confirmation-text">
              <input type="checkbox" required />
              <p>
                I acknowledge that I’ve read and understand all the instructions
                mentioned on this page, which is responsible for the safety of
                my confidential documents.
              </p>
            </div>
            <button className="secondary-btn">Apply now</button>
          </form>
        </div>
        <IndividualSideSection
          image={`${process.env.REACT_APP_BASE_URL}${service?.image}`}
          hash={service?.id}
          price={service?.price_gst_added}
          gst={service?.gst_percentage}
        />
      </section>
    </>
  );
}

export default Instructions;
