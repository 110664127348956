import React from "react";
import CommonPageHead from "../../../Components/LandingFocus/CommonPageHead";
import Order from "../../../Assets/Images/Orders.png";
import Block from "../../../Assets/Images/blockspng.png";
import LandingBasicDetails from "../../../Components/LandingFocus/LandingBasicDetails";
import Process from "../../../Assets/Images/process.png";
import Deliver from "../../../Assets/Images/deliver.png";
import TopNav from "../../../Components/TopNav/TopNav";

function About() {
  return (
    <>
      <TopNav />
      <section className="landing-working-brief ">
        <div className="landing-working-inner individual-section">
          <CommonPageHead
            heading={"About A2zdocs "}
            brief={
              <ul>
  <li>Specializing in Government Document Consultancy</li>
  <li>Offering a wide range of services and solutions</li>
  <li>Experienced team of experts</li>
  <li>Focus on innovation and customer satisfaction</li>
  <li>Use of latest technology and best practices</li>
  <li>Efficient and effective solutions</li>
  <li>Assistance with government document preparation, submission, and updating</li>
  <li>Passionate about work and committed to quality</li>
  <li>One-stop solution for all government document needs</li>
  <li>Support with specific projects available</li>
  <li>Committed to quality, innovation, and customer satisfaction</li>
  <li>Sets apart from competition and the clear choice for specialized needs.</li>
</ul>

            }
            image={Order}
            // reverse="true"
          />
          <div
            className="logo-update"
            style={{ marginTop: "-120px", marginBottom: "70px" }}
          >
            <img src={Block} alt="logo" />
            <hr />
          </div>
          <div
            className="service-individual-instructions"
            style={{ backgroundColor: "transparent", padding: "0" }}
          >
            <p>We're specialized in</p>
            <ul style={{ paddingLeft: "40px" }}>
              <li>
              Experienced professionals: Our team is made up of experienced professionals who have extensive knowledge in Government Document Consultancy.
              </li>
              <li>
              Tailored solutions: We offer tailored solutions to meet the specific needs of our clients, based on their unique circumstances and requirements.
              </li>
              <li>
              Personalized attention: We provide personalized attention to each client, ensuring that their needs are fully understood and addressed.
              </li>
            </ul>
          </div>
          <div
            className="logo-update"
            style={{ marginTop: "-60px", marginBottom: "30px" }}
          >
            <img src={Block} alt="logo" />
            <hr />
          </div>
          <LandingBasicDetails
            heading={"How we process your requested documents?"}
            brief={
              <ul>
              <li>Secure and efficient document processing</li>
              <li>Team of experts verifies information</li>
              <li>Processes request with accuracy</li>
              <li>Delivers documents via mail or in-person pickup</li>
              <li>Prioritizes privacy and security</li>
              <li>Professional handling of requested documents</li>
            </ul>

            }
            image={Process}
            reverse={true}
          />
          <LandingBasicDetails
            heading={"How we deliver your important documents safely?"}
            brief={
              <ul>
              <li>At A2Zdocs.com, we prioritize the safe delivery of your important documents.</li>
              <li>Choose between mail or in-person pickup with secure delivery options.</li>
              <li>Trust in our encryption and advanced security measures to protect your personal information at all times.</li>
              <li>Rest easy knowing your documents are in good hands.</li>
            </ul>
            }
            image={Deliver}
          />
          <div className="order-status-top-bar"></div>
        </div>
      </section>
    </>
  );
}

export default About;
