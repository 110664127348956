import React, { useEffect, useState } from "react";
import Success from "../../../Assets/Images/success.png";
import Failed from "../../../Assets/Images/failed.png";
import { useNavigate, useParams } from "react-router-dom";
import TopNav from "../../../Components/TopNav/TopNav";
import { AiFillInfoCircle } from "react-icons/ai";
import { forceDownload } from "../../../Helpers/downloadPDF";
import Loader from "../../../Components/Loader/Loader";

function TransactionStatus() {
  const { id, status } = useParams();
  const navigate = useNavigate();
  const [submissionData, setSubmissionData] = useState(null);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/user/orderdata/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${token}`,
      },
    }).then((response) => {
      response.json().then((data) => {
        if (response.ok) {
          setSubmissionData(data);
        } else {
          alert("Something went wrong");
        }
      });
    });
  }, []);

  const retryPayment = () => {
    navigate(`/services/review/${submissionData?.form?.id}`);
  };

  function downloadResource(url, filename) {
    setLoading(true);
    const token = localStorage.getItem("token");
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `token ${token}`,
      },
      mode: "cors",
    })
      .then((response) => response.blob())
      .then((blob) => {
        let blobUrl = window.URL.createObjectURL(blob);
        forceDownload(blobUrl, filename);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        alert(e);
        setLoading(false);
      });
  }

  const downloadApp = (id) => {
    downloadResource(
      `${process.env.REACT_APP_BASE_URL}/user/order/pdf/${id}`,
      "Recipit"
    );
  };

  return (
    <>
      <TopNav />
      <section className="service-individual-container">
        <div className="service-individual-detailed">
          <div className="service-individual-head">
            <h1>
              {status === "success" ? "Payment successfull" : "Payment Failed"}
            </h1>
            <h3>Order Summary</h3>
          </div>

          <div className="review-mid-container">
            <div className="review-img-container">
              <div className="review-img">
                <img
                  src={status === "success" ? Success : Failed}
                  alt="individual-img"
                />
              </div>
              {status === "success" ? (
                loading ? (
                  <Loader />
                ) : (
                  <button
                    onClick={() =>
                      downloadApp(submissionData?.order_payment_id)
                    }
                    className="secondary-btn"
                  >
                    Download application
                  </button>
                )
              ) : (
                <button onClick={retryPayment} className="secondary-btn">
                  Retry Payment
                </button>
              )}
            </div>
            <hr />
            <div className="review-details">
              {status === "success" ? (
                <p>Application id : {submissionData?.order_payment_id}</p>
              ) : (
                <p>
                  Document Type:
                  {submissionData?.form?.class_type?.details?.name}
                </p>
              )}

              <p>Deliver to : {submissionData?.form?.name}</p>
              <p>Address : {submissionData?.form?.delivery_address}</p>
              <p>Pin code : {submissionData?.form?.pincode}</p>
              <p>Mobile no : +91 {submissionData?.form?.phone_number}</p>
            </div>
            <div className="review-pricing service-individual-pricing-section">
              <h4>
                <sup>₹</sup>
                <span>{submissionData?.order_amount}</span>
              </h4>
              <span className="popup-container" style={{ marginTop: "10px" }}>
                *gst included
                <span className="popup-container">
                  <AiFillInfoCircle />
                  <div className="popup-service">
                    +
                    {submissionData?.form?.class_type?.gst_percentage?.toString()}
                    % gst included
                  </div>
                </span>
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TransactionStatus;
