import React, { useEffect, useState } from "react";
import "./orderstatus.css";
import Whatfor from "../../../Assets/Images/whatfor.png";
import LandingBasicDetails from "../../../Components/LandingFocus/LandingBasicDetails";
import CommonPageHead from "../../../Components/LandingFocus/CommonPageHead";
import Order from "../../../Assets/Images/Orders.png";
import { BiSearchAlt } from "react-icons/bi";
import { HiOutlineDocumentText } from "react-icons/hi";
import Loader from "../../../Components/Loader/Loader";
import TopNav from "../../../Components/TopNav/TopNav";
import { forceDownload } from "../../../Helpers/downloadPDF";

function OrderStatus() {
  const token = localStorage.getItem("token");
  const [orders, setOrders] = useState([]);
  const [loader, setLoader] = useState(false);
  const [q, setQ] = useState("");
  const [searchParam] = useState(["name", "last_name", "order_product"]);
  const [filterParam, setFilterParam] = useState(["All"]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoader(true);
    fetch(`${process.env.REACT_APP_BASE_URL}/user/allorderdata`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `token ${token}`,
      },
    }).then((response) => {
      response.json().then((data) => {
        if (response.ok) {
          console.log(data);
          setOrders(data);
          setLoader(false);
        } else {
          alert("Something went wrong");
          setLoader(false);
        }
      });
    });
  }, []);

  const data = Object.values(orders);

  function search(items) {
    return items.filter((item) => {
      if (item?.form?.name == filterParam) {
        return searchParam.some((newItem) => {
          return item.form[newItem].toLowerCase().indexOf(q.toLowerCase()) > -1;
        });
      } else if (filterParam == "All") {
        return searchParam.some((newItem) => {
          if (newItem === "order_product") {
            return item[newItem]?.toLowerCase().indexOf(q.toLowerCase()) > -1;
          }
          return (
            item?.form[newItem]?.toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      }
    });
  }

  function downloadResource(url, filename) {
    setLoading(true);
    const token = localStorage.getItem("token");
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `token ${token}`,
      },
      mode: "cors",
    })
      .then((response) => response.blob())
      .then((blob) => {
        let blobUrl = window.URL.createObjectURL(blob);
        forceDownload(blobUrl, filename);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        alert(e);
        setLoading(false);
      });
  }

  const downloadApp = (id) => {
    downloadResource(
      `${process.env.REACT_APP_BASE_URL}/user/order/pdf/${id}`,
      "Recipit"
    );
  };

  return (
    <>
      <TopNav />
      <section className="landing-working-brief ">
        <div className="landing-working-inner individual-section">
          <CommonPageHead
            heading={"Orders "}
            brief={
              "Get any document at your door steps. Apply for any service and try hassle free procedure."
            }
            image={Order}
            // reverse="true"
          />
          {loader && <Loader />}
          {orders?.length > 0 && loader === false && (
            <>
              <div className="order-status-search">
                <h1 className="service-all-heading">
                  <span className="search-service">
                    <BiSearchAlt />
                    <input
                      type="text"
                      placeholder="search document..."
                      value={q}
                      onChange={(e) => setQ(e.target.value)}
                    />
                  </span>
                </h1>
              </div>
              <div className="order-status-top-bar">
                <table>
                  <tr>
                    <th></th>
                    <th>Order items</th>
                    <th>Issued on name</th>
                    <th>Date of order</th>
                    <th>Download Application</th>
                  </tr>

                  {orders?.length > 0 &&
                    search(data).map((order) => {
                      return (
                        <tr key={order.form.id}>
                          <td>
                            <HiOutlineDocumentText />
                          </td>
                          <td>{order.order_product}</td>
                          <td>
                            {order.form.name}&nbsp;{order.form.last_name}
                          </td>
                          <td>{order.order_date}</td>
                          <td>
                            {loading ? (
                              "Please wait..."
                            ) : (
                              <button
                                onClick={() =>
                                  downloadApp(order.order_payment_id)
                                }
                                className="primary-btn"
                              >
                                Download
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </table>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
}

export default OrderStatus;
