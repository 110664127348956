import React from "react";
import { Link } from "react-router-dom";

function ServiceCard({ name, serviceImg, hash }) {
  return (
    <>
      <div className="landing-individual-item">
        <h3>{name}</h3>
        <div className="landing-individual-item-image">
          <img src={serviceImg} alt="adhar-card" />
        </div>
        <div className="landing-btn-fields">
          <Link
            to={`/services/instructions/${hash}`}
            className="landing-individual-btn secondary-btn"
          >
            Apply
          </Link>
          {/* <Link
            to={`/services/instructions/${hash}`}
            className="landing-individual-btn secondary-btn"
          >
            Update
          </Link> */}
        </div>
      </div>
    </>
  );
}

export default ServiceCard;
