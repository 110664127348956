import "./App.css";
import { React, useState, useReducer, createContext, useContext } from "react";
import Authentication from "./Pages/Authentication/Authentication";
import Home from "./Pages/Home/Home";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Error from "./Pages/Error/Error";
import { AnimatePresence } from "framer-motion";
import { useEffect } from "react";
import ProtectedRoutes from "./Auth/ProtectedRoute";
import Landing from "./Pages/Home/Landing/Landing";
import Services from "./Pages/Home/Services/Services";
import About from "./Pages/Home/About/About";
import Instructions from "./Pages/Home/Instructions/Instructions";
import ApplyForm from "./Pages/Home/ApplyForm/ApplyForm";
import ReviewAndPay from "./Pages/Home/ReviewAndPay/ReviewAndPay";
import TransactionStatus from "./Pages/Home/TransactionStatus/TransactionStatus";
import Footer from "./Components/Footer/Footer";
import TopNav from "./Components/TopNav/TopNav";
import Submission from "./Pages/Home/Submission/Submission";
import Profile from "./Pages/Home/Profile/Profile";
import OrderStatus from "./Pages/Home/OrderStatus/OrderStatus";
import ReactChatBot from "./ReactChatBot";

export const formContext = createContext();

const initialState = {
  formData: {},
};
export const ACTIONS = {
  FORM_DATA: "form-data",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.FORM_DATA:
      return {
        ...state,
        formData: { ...action.payload },
      };

    default:
      return state;
  }
};

function App() {
  const TransitionRoutes = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
      <formContext.Provider
        value={{ formContent: state, formContentDispatch: dispatch }}
      >
        <AnimatePresence>
          <Routes>
            {/*
          <Route
            path="/"
            element={<Navigate to="/Authentication/login" replace />}
          />
           */}

            <Route path="/services" element={<Services />} />
            <Route path="/about" element={<About />} />

            <Route
              path="/services/instructions/:id"
              element={<Instructions />}
            />
            <Route path="/" element={<Landing />} />

            <Route path="/*" element={<Home />}></Route>

            <Route
              path="/Authentication/*"
              element={<Authentication />}
            ></Route>
            <Route
              path="/Authentication"
              element={<Navigate to="/Authentication/login" replace />}
            />
          </Routes>
          {/* <UploadingMessaage /> */}
        </AnimatePresence>
      </formContext.Provider>
    );
  };

  return (
    <>
      <div className="App">
        <Router>
          {/* <TopNav /> */}
          <TransitionRoutes />
          <Footer />
        </Router>
        <ReactChatBot />
      </div>
    </>
  );
}

export default App;
