import React from "react";

function WorkingStep({ icon, content }) {
  return (
    <>
      <div className="landing-working-step-item">
        {icon}
        <p>{content}</p>
      </div>
    </>
  );
}

export default WorkingStep;
