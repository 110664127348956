import React from "react";
import ReactDOM from "react-dom";
import "./verification.css";

function Verification({ open, children }) {
  if (!open) return null;

  return ReactDOM.createPortal(
    <section className="verification-model">
      <div className="verification-window">{children}</div>
    </section>,
    document.getElementById("modal-portal")
  );
}

export default Verification;
