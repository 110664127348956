import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Kommunicate from "@kommunicate/kommunicate-chatbot-plugin";

Kommunicate.init(process.env.REACT_APP_CHATBOT_KEY);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
