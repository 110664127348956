import React from "react";
import { Link } from "react-router-dom";
import { AiFillInfoCircle } from "react-icons/ai";

function IndividualSideSection({ image, price, hash, gst }) {
  return (
    <>
      <div className="service-individual-box">
        <div className="service-individual-side-details">
          <div className="service-individual-main">
            <div className="service-individual-image">
              <img src={image} alt="indivdual-service" />
            </div>
            <div className="service-individual-basic-detail">
              <p>Get it delivered at your doorstep at</p>

              <div className="service-individual-basic-detail service-individual-pricing-section">
                <h4>
                  <sup>₹</sup>
                  <span>{price}</span>
                </h4>
                <span className="popup-container">
                  *gst included
                  <span className="popup-container">
                    <AiFillInfoCircle />
                    <div className="popup-service"> +{gst}% gst included </div>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <p>
            Kindly read all the instructions carefully and click on proceed to
            fill out the form.
          </p>
        </div>
        <div className="service-individual-side-update">
          <p>Want to update existing document?</p>
          <Link to={`/services`} className="secondary-btn">
            Update document
          </Link>
        </div>
      </div>
    </>
  );
}

export default IndividualSideSection;
