import React from "react";
import Multicard from "../../Assets/Images/multicard.png";
function LandingFocus() {
  return (
    <>
      <div className="landing-main">
        <div className="landing-details">
          <h1>Getting documents at doorstep is</h1>

          <div className="landing-our-work">
            <div className="landing-our-work-items">Convenient</div>
            <div className="landing-our-work-items">Easy</div>
            <div className="landing-our-work-items">Awesome</div>
            <div className="landing-our-work-items">& Possible!</div>
          </div>
          <p>
          "Say goodbye to frustration & long lines, get your government documents online."
          </p>
          <button className="primary-btn landing-explore-btn">
            Explore more
          </button>
        </div>

        <div className="landing-main-image">
          <img src={Multicard} alt="multicard-png" />
        </div>
      </div>
    </>
  );
}

export default LandingFocus;
