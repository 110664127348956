import React, { useEffect } from "react";
import Login from "./Login/Login";
import Register from "./Register/Register";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import { Routes, Route } from "react-router-dom";
import Error from "../Error/Error";
import PublicRoutes from "../../Auth/PublicRoute";
import Activation from "./Activation/Activation";
import ResetPassword from "./ResetPassword/ResetPassword";

function Authentication() {
  return (
    <>
      <Routes>
        <Route path="/" element={<PublicRoutes />}>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="resetpassword" element={<ForgotPassword />} />
          <Route path="activation/:token" element={<Activation />} />
          <Route path="resetpassword/:token" element={<ResetPassword />} />
        </Route>
        <Route path="*" element={<Error />} />
      </Routes>
    </>
  );
}

export default Authentication;
