import React, { useState } from "react";
import "../Login/login.css";
import Loader from "../../../Components/Loader/Loader";
import Verification from "../../../Components/Verification/Verification";
import { Link } from "react-router-dom";
import Logo from "../../../Assets/logo.png";
import TopNav from "../../../Components/TopNav/TopNav";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const [returnStatus, setReturnStatus] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const forgotPassword = async (e) => {
    e.preventDefault();
    setLoader(true);

    const header = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const api = await fetch(
      `${process.env.REACT_APP_BASE_URL}/user/forgotpassword/${email}`,
      header
    );
    const forgotStatus = api.json();
    setLoader(false);
    {
      api.ok
        ? setOpenModal(true)
        : alert(
            "Server Error: ",
            forgotStatus.message,
            "HTTP Status: ",
            api.status
          );
    }
    setReturnStatus(forgotStatus.message);
    console.log(forgotStatus.message);
  };
  return (
    <>
      <TopNav />
      <section
        className="service-individual-container"
        style={{ textAlign: "center" }}
      >
        <div className="service-individual-detailed">
          <div className="service-individual-head">
            {/* <h1>Apply for new Aadhar Card</h1> */}
            <h3>Make documentation easy with a2zdocs</h3>
          </div>
        </div>
      </section>
      <div className="authentication">
        <div className="left login-temp-sec">
          <div className="left-inner forgot-box-temp">
            <div className="forgot-pass-form">
              <div
                className="seperator"
                style={{ width: "75%", margin: "auto" }}
              >
                <span>Forgot password?</span>
              </div>

              <form onSubmit={forgotPassword}>
                <div className="form-group">
                  <label>E-mail</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  {loader ? (
                    <Loader />
                  ) : (
                    <button className="login-signup-btn" type="submit">
                      RESET PASSWORD
                    </button>
                  )}
                </div>
                <div style={{ marginTop: "20px" }} className="create-aacount">
                  Not have account ?{" "}
                  <Link to={"/Authentication/register"}>Create account</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {openModal ? (
          <Verification open={openModal}>
            <p>
              We have sent a Reset Password link to your mail, Check your
              registered email. <br /> Verify your email address:{" "}
              <span
                style={{
                  fontWeight: "bold",
                  color: "black",
                  wordBreak: "break-word",
                }}
              >
                {email}
              </span>
            </p>
            <Link
              to="/Authentication/login"
              style={{ textDecoration: "none" }}
              className="back-to-signin"
              onClick={() => setOpenModal(false)}
            >
              Back to signin
            </Link>
          </Verification>
        ) : null}
      </div>
    </>
  );
}

export default ForgotPassword;
